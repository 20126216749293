import { LandingWrapperHookOutput } from "../types"

export const useLandingWrapper = (): LandingWrapperHookOutput => {

    
    const benefitSlides = [
        {
            id: 'slide',
            title: 'Real-time<br />performance<br />tweaking.',
            text: 'With Oxion your idea comes to life in a digital twin, allowing you to make instant design tweaks. Experiment with antenna placement, clearance, and PCB size while receiving immediate performance feedback.'
        },
        {
            id: 'slide',
            title: 'Guided from<br />concept to market.',
            text: 'Oxion will be your connectivity copilot through out the entire design process, from idea conception to production.'
        },
        {
            id: 'slide',
            title: 'Demystifying<br />RF for all',
            text: 'Whether you have little to no RF expertise or you are a seasoned veteran, Oxion demystifies antenna integration and allows you to work with confidence and autonomy by making the process transparent and uncomplicated.'
        },
        {
            id: 'slide',
            title: 'Created<br />by antenna<br />innovators.',
            text: 'Oxion benefits from the knowledge and experience of the Ignion team with more than 50 million wireless devices deployed across all market segments and all wireless standards.'
        }
    ]

    const testimonials = [
        {
            color: 'pink',
            person: 'Brent Halverson',
            company: 'Petronix Wear Inc.', 
            testimonial: 'As having spent most of my career with software development, the tool is very useful for understanding RF. Oxion helps companies without RF experts to get products to market by guiding on how to get the antenna integration right.'
        },
        {
            color: '',
            person: 'Kees Hogenhout',
            company: 'RF Engineer', 
            testimonial: 'While we found the LTE antenna from Ignion so unique, the Ignion Antenna Intelligence Cloud™ service shortened our time to market extensively. We were able to cover all the LTE required bands, in Europe and in the United States on a single Virtual Atenna®.'
        },
        {
            color: 'green',
            person: 'Dan Shey',
            company: 'VP of Enabling Platforms at ABI Research', 
            testimonial: 'Oxion™ is a unique offering that will save wireless developers time and cost. It is exciting to see Ignion embrace the innovation that AI/ML can deliver when combined with their years of expertise'
        },
    ]

    
    return {
        state: {
            benefitSlides,
            testimonials
        },
        actions: {
        },
    }
}