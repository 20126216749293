import React from 'react';
import LandingLayout from '@/layout/landingLayout';
import { NextPageWithLayout } from '@/types/pages';
import { LandingWrapper } from '@/components/Landing/LandingWrapper';


const Landing: NextPageWithLayout   = () => {
      return (
        <main className="landing-oxion">
          <LandingWrapper />
        </main>
      );
}

Landing.getLayout = function getLayout(page) {
  return <LandingLayout>{page}</LandingLayout>
}

export default Landing;