import { FC } from "react"

type TestimonialProps = {
    color?: string,
    person: string,
    company: string,
    testimonial: string
}

const Testimonial: FC<TestimonialProps> = ({color, person, company, testimonial}) => {    
    return (
        <div className={`testmonial splide__slide ${color}`}>
            <div className="header">
                <div className="bg"><span></span></div>
                {testimonial}
            </div>
            <div className="footer">
                <div className="bg"><span></span></div>
                <h4 className="text">{person}</h4>
                <span>{company}</span>
            </div>
        </div>
    )
}


export default Testimonial

                        