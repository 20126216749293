import { useI18nContext } from "@/i18n/i18n-react";
import { useLayoutEffect } from "react";


export default function SignInFooterRedirect() {
  const { LL } = useI18nContext()

  useLayoutEffect(() => {
    const formEntity = document.querySelector('.authenticator-form form') as HTMLElement
    if (formEntity) {
      formEntity.id = `formSignin`
    }

    const btForm = document.querySelector('.authenticator-form form button.amplify-button--primary') as HTMLElement
    if (btForm) {
      btForm.id = `btSignin`
    }
  }, []);
  
  return (
    <div className='flex flex-row Oxion-small-regular justify-content-between sigin-footer'>
      <div className='flex align-items-center'>
        <span>{LL.signin.registerLabel()}</span>
        <a href="/signup">{LL.signin.registerLink()}</a>
      </div>
      <div className='w-max'>
        <a href="/forgot-password">{LL.signin.forgotPasswordLink()}</a>
      </div>
    </div>
  );
}